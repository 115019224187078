// Generated by Framer (03f754e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {slfv66MxR: {hover: true}};

const cycleOrder = ["slfv66MxR", "RMXQCN81r"];

const serializationHash = "framer-jDT2w"

const variantClassNames = {RMXQCN81r: "framer-v-ml108i", slfv66MxR: "framer-v-skqiiy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Standard Accent": "slfv66MxR", "Variant 2": "RMXQCN81r"}

const getProps = ({height, id, link2, tap, title, width, ...props}) => { return {...props, AzZNlMa5C: tap ?? props.AzZNlMa5C, hyoIEBZCr: title ?? props.hyoIEBZCr ?? "Contato", OYiOKVLxb: link2 ?? props.OYiOKVLxb, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "slfv66MxR"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;tap?: any;link2?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, hyoIEBZCr, AzZNlMa5C, OYiOKVLxb, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "slfv66MxR", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTappdlnu7 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (AzZNlMa5C) {const res = await AzZNlMa5C(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={OYiOKVLxb} nodeId={"slfv66MxR"} openInNewTab={false} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-skqiiy", className, classNames)} framer-1iq7ydk`} data-framer-name={"Standard Accent"} data-highlight data-reset={"button"} layoutDependency={layoutDependency} layoutId={"slfv66MxR"} onTap={onTappdlnu7} ref={ref ?? ref1} style={{backgroundColor: "var(--token-41e2e4df-e1e2-4ec0-bbfe-3c3fc0a9def4, rgb(154, 205, 50))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{"slfv66MxR-hover": {backgroundColor: "rgb(19, 84, 54)"}}} {...addPropertyOverrides({"slfv66MxR-hover": {"data-framer-name": undefined}, RMXQCN81r: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlI7SW50ZXJEaXNwbGF5", "--framer-font-family": "\"Inter Display\", \"Inter Display Placeholder\", sans-serif", "--framer-line-height": "16px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Contato</motion.p></React.Fragment>} className={"framer-ne0mq7"} fonts={["FR;InterDisplay"]} layoutDependency={layoutDependency} layoutId={"Sje7ITKwX"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-current-text-color": "hsl(0, 0%, 0%)", "--framer-link-hover-text-color": "#666"}} text={hyoIEBZCr} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jDT2w.framer-1iq7ydk, .framer-jDT2w .framer-1iq7ydk { display: block; }", ".framer-jDT2w.framer-skqiiy { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: visible; padding: 12px 16px 12px 16px; position: relative; text-decoration: none; width: min-content; }", ".framer-jDT2w .framer-ne0mq7 { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jDT2w.framer-skqiiy { gap: 0px; } .framer-jDT2w.framer-skqiiy > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-jDT2w.framer-skqiiy > :first-child { margin-left: 0px; } .framer-jDT2w.framer-skqiiy > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 88
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"RMXQCN81r":{"layout":["auto","auto"]},"Pzu3R8pVg":{"layout":["auto","auto"]}}}
 * @framerVariables {"hyoIEBZCr":"title","AzZNlMa5C":"tap","OYiOKVLxb":"link2"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framert4ZqCVc2V: React.ComponentType<Props> = withCSS(Component, css, "framer-jDT2w") as typeof Component;
export default Framert4ZqCVc2V;

Framert4ZqCVc2V.displayName = "Buttons/Buttons";

Framert4ZqCVc2V.defaultProps = {height: 40, width: 88};

addPropertyControls(Framert4ZqCVc2V, {variant: {options: ["slfv66MxR", "RMXQCN81r"], optionTitles: ["Standard Accent", "Variant 2"], title: "Variant", type: ControlType.Enum}, hyoIEBZCr: {defaultValue: "Contato", displayTextArea: false, placeholder: "", title: "Title", type: ControlType.String}, AzZNlMa5C: {title: "Tap", type: ControlType.EventHandler}, OYiOKVLxb: {title: "Link 2", type: ControlType.Link}} as any)

addFonts(Framert4ZqCVc2V, [{explicitInter: true, fonts: [{family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/2uIBiALfCHVpWbHqRMZutfT7giU.woff2", weight: "400"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/Zwfz6xbVe5pmcWRJRgBDHnMkOkI.woff2", weight: "400"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/U9LaDDmbRhzX3sB8g8glTy5feTE.woff2", weight: "400"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/tVew2LzXJ1t7QfxP1gdTIdj2o0g.woff2", weight: "400"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/DF7bjCRmStYPqSb945lAlMfCCVQ.woff2", weight: "400"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/nCpxWS6DaPlPe0lHzStXAPCo3lw.woff2", weight: "400"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/vebZUMjGyKkYsfcY73iwWTzLNag.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})